<template>
  <ClientOnly>
    <div id="chat" ref="chat" :class="!isChatVisible && 'hide-chat'" :style="style">
      <div
        class="chatOuter"
      >
        <div class="">
          <div ref="chatBoxOuter" class="chatHeader d-flex p-2 border-bottom">
            <h4 class="mb-0 d-flex align-items-center gap-2 position-relative">
              <Icon icon="material-symbols-light:support-agent-outline-sharp" class="text-lg text-purple " /> <span class="text-lg text-primary d-inline-flex position-relative">KI-Worker<span
                class="status" :class="onlineStatus === 'waiting' && 'bg-secondary',
                onlineStatus === 'offline' && 'bg-primary',
                onlineStatus === 'online' && 'bg-green'"
              /></span>
            </h4>
            <div
              @click="toggleChat" class="cursor-pointer position-absolute end-0 top-0"
            >
              <div class="d-inline-flex">
                <Icon
                  icon="material-symbols-light:close-small-outline" class="transition-all duration-300 text-xl"
                />
              </div>
            </div>
          </div>
          <div class="chatBoxOuter">
            <div ref="chatBox" class="text-sm overflow-auto w-100 h-100">
              <div ref="messages">
                <template v-for="message in chatHistory">
                  <div
                    class="mt-3 d-flex mb-3 px-3 type_default position-relative" :class="message.user === 'bot' ? `gap-3 float-left type_${message.user}` : 'type_user float-right justify-content-end'"
                  >
                    <div v-if="message.user === 'bot'" class="botImage">
                      <NuxtImg src="/images/profile.webp" class="w-full" />
                    </div>

                    <div
                      class="text-sm px-2 py-2 break-word w-full rounded-md shadow-md" :class="message.user === 'bot' ? 'message_bot' : 'message_default'"
                    >
                      {{ message.content }}
                    </div>
                  </div>
                  <div class="clear-both" />
                </template>
              </div>
              <div
                class="w-full px-2 type_default type_loading relative pointer-events-none gap-3 float-left mb-3" :class="statusmessage === '' || statusmessage === 'Online' ? 'd-none' : ' d-flex '"
              >
                <div class="botImage">
                  <NuxtImg src="/images/profile.webp" class="w-100" />
                </div>

                <div
                  class="light:text-black/70 text-white/70 break-word bg-stone-800 light:bg-stone-200 rounded-tl-none rounded-md overflow-hidden shadow-md"
                >
                  <div class="flex space-x-1 justify-center items-center !px-2 !py-1.5 min-h-full">
                    <span class="sr-only text-xs font-poppins font-bold">Loading...</span>
                    <div class="h-1.5 w-1.5 bg-white light:bg-black rounded-full animate-bounce [animation-delay:-0.3s]" />
                    <div class="h-1.5 w-1.5 bg-white light:bg-black rounded-full animate-bounce [animation-delay:-0.15s]" />
                    <div class="h-1.5 w-1.5 bg-white light:bg-black rounded-full animate-bounce" />
                  </div>
                </div>
              </div>
            </div>
            <div class="position-absolute w-100 bottom-0 p-2 w-full pt-2 border-top">
              <form @submit.prevent="sendMessage" class="flex w-full flex-nowrap flex-1">
                <div class="input-group">
                  <input id="inp" v-model="chatMessage" type="text" class="form-control" placeholder="Ihre Nachricht" autocomplete="off">
                  <div class="input-group-append">
                    <Button html-type="primary" type="submit" icon="mynaui:send-solid" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { useDraggable, useElementSize, useWindowSize } from "@vueuse/core";

const windowSize = useWindowSize();

const isChatVisible = useState("isChatVisible");
const chatMessage = ref("");
const chatHistory = ref([
  {
    user: "bot",
    content: "Guten Tag! Wie kann ich Ihnen heute helfen?",
  },
]);
const statusmessage = ref("Online");
const onlineStatus = ref("online");
const chat = ref(null);
const chatBoxOuter = ref(null);
const { width, height } = useElementSize(chat);
const chatBox = ref(null);
const messages = ref(null);

const config = useRuntimeConfig();

const { x, y, style } = useDraggable(chatBoxOuter, {
  initialValue: { x: 234, y: (windowSize.height.value - height.value - 44) },
});

function sendMessage() {
  if (chatMessage.value) {
    chatHistory.value.push({ user: "user", content: chatMessage.value });
    chatMessage.value = "";
    scrollChatToBottom();
  }
}

function toggleChat() {
  scrollChatToBottom();

  isChatVisible.value = !isChatVisible.value;
}

watch(() => isChatVisible.value, () => {
  if (!isChatVisible.value) {
    x.value = 234;
    y.value = (windowSize.height.value - height.value - 44);
  } if (isChatVisible.value) {
    y.value = (windowSize.height.value - height.value - 44);
  }
});

watch(() => x.value, () => {
  if (x.value < 234) {
    x.value = 234;
  }
  if (x.value > (windowSize.width.value - width.value - 24)) {
    x.value = (windowSize.width.value - width.value - 24);
  }
});
watch(() => y.value, () => {
  if (y.value < 100) {
    y.value = 100;
  }
  if (y.value > (windowSize.height.value - height.value - 10)) {
    y.value = (windowSize.height.value - height.value - 10);
  }
});

function hideChat() {
  scrollChatToBottom();
  isChatVisible.value = false;
}

function scrollChatToBottom() {
  nextTick(() => {
    if (chatBox) {
      chatBox.value.scrollTo({
        top: 99999999,
        behavior: "smooth",
      });
    }
  });
}
</script>

<style scoped>
</style>
